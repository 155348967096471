import Desktop from './desktop';
import Mobile from './mobile';


function Home() {
  if(window.innerWidth>=window.innerHeight){
    return <Desktop />
}
else{
    return <Mobile />
}
}

export default Home;