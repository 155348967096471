import React from 'react';
import {Helmet} from 'react-helmet-async';
import Footer from '../../functional/footer/mobFooter';
import Logo from '../../functional/logo-rect';
import imgs from './data';

function mobile() {
  return (
    <div className="fullDev column relative">
      <Helmet>
      <title>iLorem Photography Commercial</title>
      <meta
      name="description"
      content="iLorem Photography fornisce servizi fotografici per campagne pubblicitarie, profili social e siti internet. Specializzati nella fotografia di prodotti per e-commerce tra cui abbigliamento e gioielleria."
    />
      </Helmet>
      <div className='absolute column' style={{top:'20px'}}> 
      <div className='row' style={{width:'90vw',border:'1px solid rgb(50,50,50)'}}>
        <Logo svgStyle={{width:'70vw'}} pathStyle={{fill:'rgb(50,50,50)'}} />

      </div>
      <h1 style={{fontSize:'12vw',margin:'20px 0 10px 0'}}>commercial</h1>
      {
        imgs.map((img)=>{
          return(
            <img onLoad={(e)=>{e.target.style.opacity='1'}}
                 key={img.src}
                 alt={img.alt}
                 src={img.src}
                 style={{width:'90vw',margin:'20px 0',opacity:'0',transition:'300ms ease-in-out'}} />
          );
        })
      }
      <Footer/>
      </div> 
     
    </div>
  )
}

export default mobile;