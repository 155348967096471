const bucket = 'https://c0r3test.s3.eu-central-1.amazonaws.com/ilorem';

const portraits = [
    {
        alt:'Set fotografici con modelle',
        src: bucket + '/port-1.jpg'
    },
    {
        alt:'Ritratto in set fotografico',
        src: bucket + '/port-2.jpg'
    },
    {
        alt:'Fotografia lifestyle all\'aperto',
        src: bucket + '/port-3.jpg'
    },
    {
        alt:'Foto commercial per abiigliamento',
        src: bucket + '/port-4.jpg'
    },
    {
        alt:'Portrait commercial anastasia',
        src: bucket + '/port-5.jpg'
    },
    {
        alt:'Portrait commercial abbigliamento e accessori',
        src: bucket + '/port-6.jpg'
    },
    {
        alt:'Set piscina modella con fenicottero',
        src: bucket + '/port-7.jpg'
    },
];

export default portraits;