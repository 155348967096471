import React from 'react';
import {Helmet} from 'react-helmet-async';
import Footer from '../../functional/footer/mobFooter';
import Logo from '../../functional/logo-rect';
import { ExternalLink as ExLink } from '../../functional/icons';

function mobile() {
  return (
    <div className="fullDev column relative">
      <Helmet>
      <title>iLorem Photography Contatti</title>
      <meta
      name="description"
      content="Qui puoi trovare i contatti per richiedere informazioni sui servizi iLorem."
    />
      </Helmet>
      <div className='absolute column' style={{top:'20px'}}> 
      <div className='row' style={{width:'90vw',border:'1px solid rgb(50,50,50)'}}>
        <Logo svgStyle={{width:'70vw'}} pathStyle={{fill:'rgb(50,50,50)'}} />

      </div>

      <h1 style={{fontSize:'12vw',margin:'20px 0 10px 0'}}>CONTATTI</h1>
      <div className='column' style={{alignSelf:'flex-end',width:'90vw',height:'90vw',border:'1px solid rgb(50,50,50)',alignItems:'flex-end',justifyContent:'space-between'}}>
          <div className='column' style={{alignItems:'flex-end',margin:'20px 20px 0 0'}}>
            <h3 style={{fontSize:'4vw'}}>E-MAIL</h3>
            <p style={{fontSize:'4vw',margin:'0'}}>iloremgroup@gmail.com</p>
          </div>
          <a style={{textDecoration:'none',color:'rgb(50,50,50)'}} href="tel:+393737175312" >
            <div className='column' style={{alignItems:'flex-end',margin:'0 20px 0 0'}}>
              <h3 style={{fontSize:'4vw'}}>TELEFONO</h3>
              <p style={{fontSize:'4vw',margin:'0'}}>+39 373-7175312</p>
            </div>
          </a>
          
          <a style={{color:'rgb(50,50,50)',textDecoration:'none'}} target='_blank' rel='noopener noreferrer' href="https://wa.me/+393737175312">
                    <div className='row' style={{margin:'0 20px 20px 0'}}>
                    <h3 style={{fontSize:'4vw',}}>WHATSAPP</h3>
                     <ExLink svgStyle={{width:'4vw',fill:'rgb(50,50,50)',margin:'0 0 0 1vw'}} />
                    </div>
               </a>
          

      </div>
      <Footer/>
      </div> 
        
    </div>
  )
}

export default mobile;