import React from 'react';
import Logo from '../../functional/logo-rect';
import {Helmet} from 'react-helmet-async';

function mobile() {
  return (
    <div className="fullDev column" >
      <Helmet>
      <title>iLorem Photography</title>
      <meta
      name="description"
      content="iLorem Photography. Un gruppo di professionisti nel campo del Visual Design con sede a Roma, offriamo servizi per fotografia, creazione siti web, videomaking e content creating"
    />
      </Helmet>
        <video style={{height:'100vh',width:'100vw',objectFit:'cover',margin:'0',filter:'grayscale(0)'}}
               crossOrigin="*" 
               autoPlay
               muted
               loop
               >
                 <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/ilorem/video/homecolor2.mp4' type="video/mp4" />
        </video>
        <div className='row' style={{position:'absolute',top:'20px',width:'90vw',border:'1px solid rgb(250,250,250)'}}>
          <Logo svgStyle={{width:'70vw'}} pathStyle={{fill:'rgb(250,250,250)'}} />
        </div>
       
      
    </div>
  )
}

export default mobile;
