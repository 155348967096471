import React from "react";

const C0R3 = props => {
    return(
        <svg style={props.svgStyle} version="1.1" viewBox="0 0 264.58 69.611" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-90.504 -100.43)">
             <path d="m127.34 115.9v-11.602l-6.6983-3.8673-30.142 17.403v34.805l30.142 17.403 30.142-17.403v-7.7346l-10.047-5.8009v7.7345l-20.095 11.602-20.095-11.602v-23.204l20.095-11.602z" style={props.pathStyle}/>
             <path d="m160.84 133.3 2e-5 -15.469 30.142-17.403 12.758 7.7345z" style={props.pathStyle}/>
             <path d="m160.84 136.56-4e-5 16.083 13.925 8.0446 0.39102-0.6896-13.541-7.8089-4e-5 -15.629z" style={props.pathStyle}/>
             <path d="m206.8 110.85 0.39102-0.6896 13.925 8.0446-5e-5 16.083h-0.77559l-4e-5 -15.629z" style={props.pathStyle}/>
             <path d="m177.58 162.31 43.539-25.137v15.469l-30.142 17.403-13.397-7.7346" style={props.pathStyle}/>
             <path transform="matrix(.26458 0 0 .26458 90.504 100.43)" d="m632.91 0-101.27 58.467v180.62l37.975 21.924v-65.773l113.92 65.773 37.975-21.924-50.633-29.234 61.117-34.664v-116.93l-99.092-58.254zm0.47461 42.445 62.816 35.857v73.082l-63.291 38.629-63.293-36.541v-75.17l63.768-35.857z" style={props.pathStyle}/>
             <path d="m294.8 144.9 10.047-5.8009v7.7345l20.095 11.602 16.746-9.6682-23.444-13.535v-3.8673l3.3492-1.9336 23.444 13.535v-19.336l-20.095-11.602-6.6983 3.8673 1e-5 -11.602 6.6983-3.8673 30.142 17.403v34.805l-30.142 17.403-30.142-17.403z" style={props.pathStyle}/>
            </g>
        </svg>
    )
}
export default C0R3;