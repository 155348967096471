import { createRef } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useOutlet,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Home from './pages/home/home';
import Portraits from './pages/portraits/portraits';
import Commercial from './pages/commercial/commercial';
import Interior  from './pages/interior/interior';
import Video  from './pages/video/video';
import Contact from './pages/contacts/contacts';
import Nav from './functional/mobNav';
import './index.css';

const routes = [
  { path: '/', name: 'HOME', element: <Home />, nodeRef: createRef() },
  { path: '/portraits', name: 'PORTRAITS', element: <Portraits />, nodeRef: createRef() },
  { path: '/commercial', name: 'COMMERCIAL', element: <Commercial />, nodeRef: createRef()},
  { path: '/interior', name: 'INTERIOR', element: <Interior />, nodeRef: createRef()},
  { path: '/video', name: 'VIDEO', element: <Video />, nodeRef: createRef()},
  { path: '/contacts', name: 'CONTATTI', element: <Contact />, nodeRef: createRef()},
  { path: '*', element: <Home />, nodeRef: createRef()},
];

const router = createBrowserRouter([
  {
    path: '/',
    element: <Ilorem />,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
    })),
  },
]);



function Ilorem() {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const { nodeRef } = routes.find((route) => route.path === location.pathname) ?? {};

  const w = window.innerWidth;
  const h = window.innerHeight;
  return (
  <HelmetProvider>
    <div className='fullDev relative dumbDiv' style={{overflowY:'scroll',overflowX:'hidden',top:'0',background:'rgb(245,245,245)'}}>
    <div className='relative column'>
      
          
      <div className="container">
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            nodeRef={nodeRef}
            timeout={300}
            classNames="page"
            unmountOnExit
          >
            {(state) => (
              <div ref={nodeRef} className="page">
                {currentOutlet}
              </div>
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
      </div>
{   w<h?
      <Nav containerStyle={{alignSelf:'flex-start',alignItems:'flex-start',position:'fixed',bottom:'5vw',width:'20vw'}} />
    :
    null
}
    </div>
    
    </HelmetProvider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<RouterProvider router={router} />)