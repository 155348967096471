const bucket = 'https://c0r3test.s3.eu-central-1.amazonaws.com/ilorem/video';

const video = [
    {   
        txt:'',
        poster:bucket +'/posters/poster-rebecca.jpg',
        src: bucket + '/rebecca.mp4'
    },
    {   
        txt:'',
        poster:bucket +'/posters/poster-comm.jpg',
        src: bucket + '/ilorem-commercial.mp4'
    },
    {   
        txt:'',
        poster:bucket +'/posters/poster-tarantinoStyle.jpg',
        src: bucket + '/ilorem-tarantino.mp4'
    },
    {   
        txt:'',
        poster:bucket +'/posters/poster-parcoDeiPrincipi.jpg',
        src: bucket + '/parco-dei-principi.mp4'
    },
    
];

export default video;