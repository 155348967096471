import React from 'react';
import {Helmet} from 'react-helmet-async';

import Head from '../../functional/deskPageHead';
import Footer from '../../functional/footer/deskFooter';
import data from './data';
function desktop() {
  return (
    <div className="column" style={{width:'100vw'}}>
     <Helmet>
      <title>iLorem Photography Video</title>
      <meta
      name="description"
      content="iLorem Photography fornisce servizi di videomaking. Creazione video pubblicitari, documentari, interviste."
    />
      </Helmet>
      <Head text={'VIDEO'} />
      
      {
           data.map((video)=>{
               return(
                    <video key={video.src}
                           crossOrigin='*'
                           controls
                           preload='metadata'
                           controlsList='nodownload nofullscreen novolume'
                           style={{width:'65vw',height:'auto',margin:'0 0 5vw 0'}}
                           poster={video.poster}>
                          <source src={video.src} type="video/mp4" />
                    </video>
               )
           })
      }
    
      <Footer/>
    </div>
  )
}

export default desktop;