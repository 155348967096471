import React from 'react';
import {Helmet} from 'react-helmet-async';

import Head from '../../functional/deskPageHead';
import Footer from '../../functional/footer/deskFooter';

import imgs from './data';
function desktop() {
  return (
    <div className="column" style={{width:'100vw'}}>
     <Helmet>
      <title>iLorem Photography Portraits</title>
      <meta
      name="description"
      content="iLorem Photography fornisce servizi fotografici per modelle, make up, set fotografici in ambiente interno ed esterno"
    />
      </Helmet>
      <Head text={'PORTRAITS'} />

    <div className='column' style={{justifyContent:'space-between',width:'65vw'}}>

      <img onLoad={(e)=>{e.target.style.opacity='1'}}
           alt={imgs[0].alt}
           src={imgs[0].src} 
           style={{width:'65vw',minHeight:'30vw',objectFit:'cover',opacity:'0',transition:'300ms ease-in-out'}} />
      
      
      <div className='row' style={{justifyContent:'space-between',width:'65vw',margin:'5vw 0'}}>
               <img alt={imgs[1].alt}
                    src={imgs[1].src} 
               style={{width:'30vw',objectFit:'cover'}} />
               <img alt={imgs[2].alt}
                    src={imgs[2].src} 
               style={{width:'30vw',objectFit:'cover'}} />
               
      </div>
      <img alt={imgs[3].alt}
            src={imgs[3].src} 
               style={{width:'65vw',objectFit:'cover'}} />
      <div className='row' style={{justifyContent:'space-between',width:'65vw',margin:'5vw 0'}}>
               <img alt={imgs[4].alt}
                    src={imgs[4].src} 
               style={{width:'30vw',objectFit:'cover'}} />
               <img alt={imgs[5].alt}
                    src={imgs[5].src} 
               style={{width:'30vw',objectFit:'cover'}} />
               
      </div>
          <img alt={imgs[6].alt}
                src={imgs[6].src} 
               style={{width:'65vw',objectFit:'cover'}} />
    </div>

     <Footer />
    </div>
  )
}

export default desktop; 