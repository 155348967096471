import React from 'react';
import Logo from '../logo-rect';
import C0R3 from './c0r3-logo';
import Nav from '../deskNav';
import {instagramIcon as Insta} from '../icons';
function mobFooter() {
  return (
    <div className='column' style={{width:'90vw',margin:'0 0 5vw 0',borderTop:'1px solid rgb(50,50,50)'}}>
        <Logo svgStyle={{width:'40vw',margin:'5vw 0'}} pathStyle={{fill:'rgb(50,50,50)'}} />
        
          <Nav containerStyle={{flexDirection:'column'}} navStyle={{fontSize:'4vw',margin:'2vw 0'}} />
          
          <a href='https://www.instagram.com/ilorem_photography/' >
          <Insta svgStyle={{width:'10vw',margin:'5vw 0',fill:'rgb(50,50,50)'}} />
        </a>
        
            
        

        
        
        <div className='column' style={{width:'30vw',height:'30vw',background:'rgb(50,50,50)'}}>
                <p style={{fontSize:'2.6vw',margin:'0 0 1.5vw 0',color:'rgb(245,245,245)'}}>DEVELOPED BY</p>
                <C0R3 svgStyle={{width:'20vw',fill:'rgb(245,245,245)'}} />
        </div>
        
        
      
        
    </div>
  )
}

export default mobFooter;