import React from 'react';
import Logo from '../logo-rect';
import C0R3 from './c0r3-logo';
import Nav from '../deskNav';
import {instagramIcon as Insta} from '../icons';
function deskFooter() {
  return (
    <div className='row' style={{width:'65vw',justifyContent:'space-between',margin:'5vw 0 0 0',padding:'2.5vw 0 2.5vw 0',borderTop:'1px solid rgb(50,50,50)'}}>
        <div className='row' style={{width:'30vw',height:'10vw',justifyContent:'space-between'}}>
          <Nav containerStyle={{flexDirection:'column',alignItems:'flex-start'}} navStyle={{fontSize:'0.8vw',margin:'0 0 0.5vw 0'}} />
        </div>
        <div className='column' style={{height:'10vw',justifyContent:'space-between'}}>
        <Logo svgStyle={{width:'10vw'}} pathStyle={{fill:'rgb(50,50,50)'}} />
        <a href='https://www.instagram.com/ilorem_photography/' >
          <Insta svgStyle={{width:'2vw',fill:'rgb(50,50,50)'}} />
        </a>

        </div>
        <div className='column' style={{width:'30vw',alignItems:'flex-end'}}>
            <div className='column' style={{width:'10vw',height:'10vw',background:'rgb(50,50,50)'}}>
                    <p style={{fontSize:'0.8vw',margin:'0 0 0.5vw 0',color:'rgb(245,245,245)'}}>DEVELOPED BY</p>
                    <C0R3 svgStyle={{width:'6vw',fill:'rgb(245,245,245)'}} />
            </div>
        </div>
        
    </div>
  )
}

export default deskFooter;