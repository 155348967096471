const bucket = 'https://c0r3test.s3.eu-central-1.amazonaws.com/ilorem';

const commercials = [
    {
        alt:'Bernard Delettrez anatomy and eyes line',
        src: bucket + '/comm-mob-1.jpg'
    },
    {
        alt:'Bernard Delettrez Snake Ring with Eye',
        src: bucket + '/comm-4.jpg'
    },
    {
        alt:'Bernard Delettrez Marble skull Ring with Snakes',
        src: bucket + '/comm-5.jpg'
    },
    {
        alt:'Bernard Delettrez Snake Ring with Pearl',
        src: bucket + '/comm-6.jpg'
    },
    {
        alt:'Foto per catalogo gioielleria',
        src: bucket + '/comm-3.jpg'
    },
    {
        alt:'Fotografia di gioielli artigianali su modella',
        src: bucket + '/comm-1.jpg'
    },
    {
        alt:'Modella con gioielli, foto pubblicitaria',
        src: bucket + '/comm-2.jpg'
    },
    
    
    
];

export default commercials;