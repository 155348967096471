const bucket = 'https://c0r3test.s3.eu-central-1.amazonaws.com/ilorem';

const interior = [
    {
        alt:'Fotografia professionale di interni',
        src: bucket + '/interior-1.jpeg'
    },
    {
        alt:'Fotografia stanza hotel',
        src: bucket + '/interior-4.jpeg'
    },
    {
        alt:'Fotografia per affittacamere e bed and breakfast',
        src: bucket + '/interior-5.jpeg'
    },
    {
        alt:'Fotografie per strutture ricettive',
        src: bucket + '/interior-2.jpeg'
    },
    {
        alt:'Fotografo professionale per foto di interni',
        src: bucket + '/interior-3.jpeg'
    },
    
    
];

export default interior;