import React from 'react';
import {Helmet} from 'react-helmet-async';
import Footer from '../../functional/footer/mobFooter';
import Logo from '../../functional/logo-rect';
import data from './data';

function mobile() {
  return (
    <div className="fullDev column relative">
      <Helmet>
      <title>iLorem Photography Video</title>
      <meta
      name="description"
      content="iLorem Photography fornisce servizi di videomaking. Creazione video pubblicitari, documentari, interviste."
    />
      </Helmet>
      <div className='absolute column' style={{top:'20px'}}> 
      <div className='row' style={{width:'90vw',border:'1px solid rgb(50,50,50)'}}>
        <Logo svgStyle={{width:'70vw'}} pathStyle={{fill:'rgb(50,50,50)'}} />

      </div>
      
      <h1 style={{fontSize:'12vw',margin:'20px 0 10px 0'}}>VIDEO</h1>
      
      {
           data.map((video)=>{
               return(
                    <video key={video.src}
                           crossOrigin='*'
                           controls
                           preload='metadata'
                           controlsList='nodownload novolume'
                           style={{width:'90vw',height:'auto',margin:'20px 0'}}
                           poster={video.poster}>
                          <source src={video.src} type="video/mp4" />
                    </video>
               )
           })
      }
      <Footer/>
       </div> 
        
    </div>
  )
}

export default mobile;