import React from 'react';
import {Helmet} from 'react-helmet-async';
import Nav from '../../functional/deskNav';
import Logo from '../../functional/logo';
import {instagramIcon as Insta} from '../../functional/icons';
function desktop() {
  return (
    <div className="fullDev column">
      <Helmet>
      <title>iLorem Photography</title>
      <meta
      name="description"
      content="iLorem Photography. Un gruppo di professionisti nel campo del Visual Design con sede a Roma, offriamo servizi per fotografia, creazione siti web, videomaking e content creating"
    />
      </Helmet>
      <Nav containerStyle={{height:'2vw',width:'65vw',justifyContent:'space-between'}}/>
      <div className='row' style={{margin:'5vw 0'}}>
        <video style={{height:'20vw',width:'40vw',objectFit:'cover',margin:'0 5vw 0 0',filter:'grayscale(100%)'}}
               crossOrigin="*"
               autoPlay
               muted
               loop
               >
                 <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/ilorem/video/try.mp4' type="video/mp4" />
               </video>
        <Logo svgStyle={{width:'20vw',fill:'none',border:'1px solid rgb(50,50,50)'}} pathStyle={{fill:'rgb(50,50,50)'}} />
      </div>
      <div className='row' style={{width:'65vw',justifyContent:'flex-end'}}>
        <Insta svgStyle={{width:'2vw',fill:'rgb(50,50,50)'}} />
      </div>
      
    </div>
  )
}

export default desktop;