import React from 'react';
import { NavLink } from 'react-router-dom';
const routes = [
    { path: '/', name: 'HOME'},
    { path: '/portraits', name: 'PORTRAITS'},
    { path: '/commercial', name: 'COMMERCIAL'},
    { path: '/interior', name: 'INTERIOR'},
    { path: '/video', name: 'VIDEO'},
    { path: '/contacts', name: 'CONTATTI'},
  ]
function deskNav(props) {
  return (
    <div className='relative row' style={props.containerStyle}>
      {routes.map((route) => (
            <NavLink
              key={route.path}
              to={route.path}
              className={({ isActive }) => (isActive ? 'active navs' : 'navs')}
              style={props.navStyle}
              end
            >
              {route.name}
            </NavLink>
          ))}
      </div>
  )
}

export default deskNav