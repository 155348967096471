import React from 'react';
import { togglerMenu, mobNavHide } from '../function';
import {instagramIcon as Insta} from './icons';
import { NavLink } from 'react-router-dom';
const routes = [
    { path: '/', name: 'HOME'},
    { path: '/portraits', name: 'PORTRAITS'},
    { path: '/commercial', name: 'COMMERCIAL'},
    { path: '/interior', name: 'INTERIOR'},
    { path: '/video', name: 'VIDEO'},
    { path: '/contacts', name: 'CONTATTI'},
  ]
function mobNav(props) {
  return (
    <div className='absolute column' style={props.containerStyle}>
      <div className='mobNavs row' style={{width:'12vw',height:'12vw',padding:'1.5vw 1vw 0 1vw',margin:'0 0 0 5vw',transform:'translateX(-55vw)',transition:'300ms 100ms ease-in-out'}}> 
      <a href='https://www.instagram.com/ilorem_photography/' >
        <Insta svgStyle={{width:'9vw',height:'9vw',fill:'rgb(0,0,0)'}} />
        </a>
      </div>
      {routes.map((route, i) => (
            <NavLink
              key={route.path}
              to={route.path}
              onClick={mobNavHide}
              className={({ isActive }) => (isActive ? 'mobNavs mobNavsAct' : 'mobNavs')}
              style={{...props.navStyle,transition:`300ms ${i * 50 +100}ms ease-in-out`}}
              end
            >
              {route.name}
            </NavLink>
      ))}
      <div className='row' onClick={togglerMenu} style={{width:'12vw',height:'12vw',margin:'2.5vw 0 0 5vw',border:'2px solid #000',background:'rgba(255,255,255,0.6)'}}>
        <svg id='mobNavToggler' style={{width:'12vw',height:'12vw',transform:'rotate(405deg)',transition:'300ms ease-in-out'}}>

          <line style={{stroke:'#000',strokeWidth:'2px'}} x1='3vw' x2='9vw' y1='3vw'y2='9vw' />
          <line style={{stroke:'#000',strokeWidth:'2px'}} x1='9vw' x2='3vw' y1='3vw'y2='9vw' />
        
        </svg>
      </div>
        

        
      </div>
  )
}

export default mobNav;