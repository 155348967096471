import React from 'react';
import {Helmet} from 'react-helmet-async';

import Head from '../../functional/deskPageHead';
import Footer from '../../functional/footer/deskFooter';
import {ExternalLink as ExLink} from '../../functional/icons';
function desktop() {
  return (
    <div className="column" style={{width:'100vw'}}>
     <Helmet>
      <title>iLorem Photography Contatti</title>
      <meta
      name="description"
      content="Qui puoi trovare i contatti per richiedere informazioni sui servizi iLorem."
    />
      </Helmet>
      <Head text={'CONTATTI'} />

      <div className='row' style={{width:'60vw',padding:'2.5vw',height:'10vw',border:'1px solid rgb(50,50,50)',justifyContent:'space-between'}}>
          <div className='column' style={{alignItems:'flex-start'}}>
               <h3>E-MAIL</h3>
               <p>iloremgroup@gmail.com</p>
          </div>
          <div className='column' style={{alignItems:'flex-start'}}>
               <h3>TELEFONO</h3>
               <p>+39 373-7175312</p>
          </div>
          <div className='column' style={{alignItems:'flex-end'}}>
               <h3>WHATSAPP WEB</h3>
               
               <a style={{color:'rgb(50,50,50)',textDecoration:'none'}} target='_blank' rel='noopener noreferrer' href="https://wa.me/+393737175312">
                    <div className='row'>

                         <p>+39 373-7175312</p>
                         <ExLink svgStyle={{width:'1vw',fill:'rgb(50,50,50)',margin:'0 0 0 1vw'}} />
                    </div>
               </a>
          </div>
      </div>
      
      <Footer />
    </div>
  )
}

export default desktop; 