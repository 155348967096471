import React from 'react';
import {Helmet} from 'react-helmet-async';

import HeadPage from '../../functional/deskPageHead';
import Footer from '../../functional/footer/deskFooter';
import imgs from './data';
function desktop() {
  return (
    <div className="column" style={{width:'100vw'}}>
     <Helmet>
      <title>iLorem Photography Commercial</title>
      <meta
      name="description"
      content="iLorem Photography fornisce servizi fotografici per campagne pubblicitarie, profili social e siti internet. Specializzati nella fotografia di prodotti per e-commerce tra cui abbigliamento e gioielleria."
    />
      </Helmet>
      <HeadPage text={'COMMERCIAL'} />

      <img alt="Bernard Delettrez anatomy and eyes line" 
           src="https://c0r3test.s3.eu-central-1.amazonaws.com/ilorem/comm-7.jpg" 
           style={{width:'65vw',objectFit:'cover'}} />
      
      
      <div className='row' onLoad={(e)=>{e.target.style.opacity='1'}} style={{justifyContent:'space-between',width:'65vw',margin:'5vw 0'}}>
               <img alt={imgs[1].alt} 
                    src={imgs[1].src} 
                    style={{width:'20vw',height:'20vw',objectFit:'cover',opacity:'0',transition:'300ms ease-in-out'}} />
               <img alt={imgs[2].alt} 
                    src={imgs[2].src} 
                    style={{width:'20vw',height:'20vw',objectFit:'cover'}} />
               <img alt={imgs[3].alt} 
                    src={imgs[3].src} 
                    style={{width:'20vw',height:'20vw',objectFit:'cover'}} />
      </div>
      
      <div className='row' style={{justifyContent:'space-between',width:'65vw'}}>
        <img alt={imgs[4].alt} 
             src={imgs[4].src} 
             style={{width:'40vw',height:'45vw',objectFit:'cover'}} />
        <div className='column'>
          <img alt={imgs[5].alt} 
               src={imgs[5].src} 
               style={{width:'20vw',height:'20vw',objectFit:'cover',marginBottom:'5vw'}} />
          <img alt={imgs[6].alt} 
               src={imgs[6].src} 
               style={{width:'20vw',height:'20vw',objectFit:'cover'}} />
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default desktop; 