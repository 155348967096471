import {useEffect} from 'react';

import Desktop from './desktop';
import Mobile from './mobile';


function Commercial() {

  useEffect(()=>{
    document.getElementsByClassName('dumbDiv')[0].scrollTop = '0';
  },[])

if(window.innerWidth>=window.innerHeight){
    return <Desktop />
}
else{
    return <Mobile />
}
}

export default Commercial;