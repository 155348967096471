import React from 'react';
import Nav from './deskNav';

function deskPageHead(props) {
  return (
    <div className='row' style={{margin:'5vw 0',width:'65vw'}}>
        <div style={{width:'12vw',height:'12vw',margin:'0 3vw 0 0',border:'1px solid rgb(50,50,50)'}} ></div>
        <div className='column' style={{alignItems:'flex-start', height:'12vw',justifyContent:'space-between'}}>
          <Nav containerStyle={{width:'50vw',flexWrap:'wrap',justifyContent:'space-between'}}
               navStyle={{margin:'0 3vw 0 0'}}/>
          <h1>{props.text}</h1>
        </div>
        
      </div>
  )
}

export default deskPageHead