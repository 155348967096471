export const mobNavAppear = () =>{
    const arr = document.getElementsByClassName('mobNavs');
    Array.from(arr).forEach(nav => {
        nav.style.transform = 'translateX(0px)';
    });
}

export const mobNavHide = () =>{
    const arr = document.getElementsByClassName('mobNavs');
    const toggler = document.getElementById('mobNavToggler');
    Array.from(arr).forEach(nav => {
        nav.style.transform = 'translateX(-55vw)';
    });
    toggler.style.transform = 'rotate(405deg)';
}

export const togglerMenu =()=>{
    const arr = document.getElementsByClassName('mobNavs');
    const toggler = document.getElementById('mobNavToggler');
  
    if(arr[0].style.transform==='translateX(0px)'){
        Array.from(arr).forEach(nav => {
            nav.style.transform = 'translateX(-55vw)';
        });
        toggler.style.transform = 'rotate(405deg)';
    }else{
        Array.from(arr).forEach(nav => {
            nav.style.transform = 'translateX(0px)';
        });
        toggler.style.transform = 'rotate(0)';
    }

}